/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-unused-vars */

import styled from '@emotion/styled';
import DraggableSelect from 'components/Form/Input/DraggableSelect/DraggableSelect';
import { createForm } from 'final-form';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationSettingsSelector } from 'store/settings/settings.selectors';
import { getSettingsSpinnerSelector } from 'store/spinner/spinner.selectors';
import { IAutocompleteField } from 'types/shared';
import { useTypedSelector } from 'utils/hooks/TypedSelector';
import { OrganizationSettingsDTO } from 'utils/restApplicationClient';
import useSettingsForm from './SettingsFormHook';
import {
  CustomMappingContainer,
  CustomMappingLegend,
} from './SettingsViewContainer.style';

export type SettingsFormFields = {
  allowedContentTypesIds: IAutocompleteField[];
  entryLevelLocalizationContentTypesIds: IAutocompleteField[];
  normalTranslationContentTypesIds: IAutocompleteField[];
  recreatedContentTypesIds: IAutocompleteField[];
};

interface IProps {
  organizationSettingsList?: OrganizationSettingsDTO;
  enabledEntryLevelLocalization?: boolean;
  disable?: boolean;
  clientId?: string;
}

const mapOptions = (
  value: string[],
  settingsMap: IAutocompleteField[],
  filter?: string[],
  aditionalFilter?: string[],
): IAutocompleteField[] => {
  return (
    value
      .map(
        (data) =>
          settingsMap.find(({ value }) => value === data) || {
            label: '',
            value: '',
          },
      )
      .filter(({ value }) => filter?.includes(value) !== true)
      .filter(({ value }) => aditionalFilter?.includes(value) !== true) || []
  );
};

const SettingsFormContainer = ({
  enabledEntryLevelLocalization,
  disable,
  clientId,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const settingsSpinner = useSelector(getSettingsSpinnerSelector);
  const currentOrganizationSettings = useTypedSelector(
    getCurrentOrganizationSettingsSelector,
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = (values: SettingsFormFields): void => {};

  const form = useMemo(
    () =>
      createForm({
        onSubmit,
      }),
    [],
  );

  const {
    handleAvailableTypesChange,
    handleEntryLevelChange,
    handleNormallyChange,
    handleRecreatedChange,
  } = useSettingsForm(form, currentOrganizationSettings, clientId);

  const settingsMap = useCallback(
    () =>
      currentOrganizationSettings?.availableContentTypes.map(
        ({ contentTypeId: value, contentTypeName: label }) => ({
          label,
          value,
        }),
      ) || [],
    [currentOrganizationSettings?.availableContentTypes],
  );

  const allowedSettingsMap = useMemo(() => {
    return currentOrganizationSettings
      ? mapOptions(
          currentOrganizationSettings.allowedContentTypesIds,
          settingsMap(),
        )
      : [];
  }, [currentOrganizationSettings]);

  const entryLevelContentMap = useMemo(() => {
    return currentOrganizationSettings?.entryLevelLocalizationContentTypesIds
      ? mapOptions(
          currentOrganizationSettings?.allowedContentTypesIds,
          settingsMap(),
          currentOrganizationSettings.normalTranslationContentTypesIds,
          currentOrganizationSettings.recreatedContentTypesIds,
        )
      : [];
  }, [currentOrganizationSettings]);

  const recreatedContentMap = useMemo(() => {
    return currentOrganizationSettings?.recreatedContentTypesIds
      ? mapOptions(
          currentOrganizationSettings?.allowedContentTypesIds,
          settingsMap(),
          currentOrganizationSettings.normalTranslationContentTypesIds,
          currentOrganizationSettings.entryLevelLocalizationContentTypesIds,
        )
      : [];
  }, [currentOrganizationSettings]);

  const normalContentMap = useMemo(() => {
    return currentOrganizationSettings?.recreatedContentTypesIds
      ? mapOptions(
          currentOrganizationSettings?.allowedContentTypesIds,
          settingsMap(),
          currentOrganizationSettings.recreatedContentTypesIds,
          currentOrganizationSettings.entryLevelLocalizationContentTypesIds,
        )
      : [];
  }, [currentOrganizationSettings]);

  const initialValues: SettingsFormFields | undefined = useMemo(() => {
    if (!currentOrganizationSettings) {
      return undefined;
    }
    const {
      allowedContentTypesIds,
      recreatedContentTypesIds,
      entryLevelLocalizationContentTypesIds,
      normalTranslationContentTypesIds,
    } = currentOrganizationSettings;

    return {
      allowedContentTypesIds: mapOptions(allowedContentTypesIds, settingsMap()),
      recreatedContentTypesIds: mapOptions(
        recreatedContentTypesIds,
        settingsMap(),
      ),
      entryLevelLocalizationContentTypesIds: mapOptions(
        entryLevelLocalizationContentTypesIds,
        settingsMap(),
      ),
      normalTranslationContentTypesIds: mapOptions(
        normalTranslationContentTypesIds,
        settingsMap(),
      ),
    };
  }, [currentOrganizationSettings]);

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    form.initialize(initialValues);
  }, [initialValues]);

  const DisableCheckWrap = styled.div`
    div > * {
      opacity: ${disable ? `0.5` : `1`};
    }
  `;

  return (
    <>
      {!!currentOrganizationSettings ? (
        <DisableCheckWrap>
          <Form
            form={form}
            onSubmit={onSubmit}
            render={({ handleSubmit }): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <CustomMappingContainer>
                  <CustomMappingLegend>
                    {t('settings.contentModels')}
                  </CustomMappingLegend>
                  <Field
                    name="allowedContentTypesIds"
                    label="settings.availableTypes"
                    options={settingsMap()}
                    component={DraggableSelect}
                    multi
                    disabled={disable}
                    spinner={settingsSpinner}
                    handleOnClose={handleAvailableTypesChange}
                    handleOnChipDelete={handleAvailableTypesChange}
                  />
                </CustomMappingContainer>
                {enabledEntryLevelLocalization ? (
                  <CustomMappingContainer>
                    <CustomMappingLegend>
                      {t('settings.entryLevelSettingsTitle')}
                    </CustomMappingLegend>
                    <Field
                      name="entryLevelLocalizationContentTypesIds"
                      label="settings.entryLevelLocalizationContentTypesIds"
                      options={entryLevelContentMap}
                      component={DraggableSelect}
                      handleOnClose={handleEntryLevelChange}
                      handleOnChipDelete={handleEntryLevelChange}
                      multi
                      disabled={disable}
                      spinner={settingsSpinner}
                    />
                    <Field
                      name="normalTranslationContentTypesIds"
                      label="settings.normalTranslationContentTypesIds"
                      options={normalContentMap}
                      component={DraggableSelect}
                      handleOnClose={handleNormallyChange}
                      handleOnChipDelete={handleNormallyChange}
                      multi
                      disabled={disable}
                      spinner={settingsSpinner}
                    />
                    <Field
                      name="recreatedContentTypesIds"
                      label="settings.recreatedContentTypesIds"
                      options={recreatedContentMap}
                      component={DraggableSelect}
                      handleOnClose={handleRecreatedChange}
                      handleOnChipDelete={handleRecreatedChange}
                      multi
                      disabled={disable}
                      spinner={settingsSpinner}
                    />
                  </CustomMappingContainer>
                ) : undefined}
              </form>
            )}
          />
        </DisableCheckWrap>
      ) : null}
    </>
  );
};

export default SettingsFormContainer;
