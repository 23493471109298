/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch } from '@reduxjs/toolkit';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import { EditData } from 'components/Form/CilentData/EditData';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomFieldRow from 'components/Form/Input/CustomFieldRow';
import FormTitle from 'components/Form/Title/FormTitle';
import LanguageMappingContainer from 'components/LanguageMapping/LanguageMappingContainer';
import arrayMutators from 'final-form-arrays';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import {
  clearClient,
  clearClientErrors,
  createClient,
  editClient,
  getAllLanguages,
  getClientById,
} from 'store/client/client.actions';
import {
  getClientErrorsSelector,
  getClientSelector,
  getContentfulLanguagesSelector,
  getXTMLanguagesSelector,
} from 'store/client/client.selectors';
import { IEditClient } from 'types/api';
import { IAutocompleteField, ILanguageMappingField } from 'types/shared';
import {
  composeValidators,
  fetchValidator,
  required,
} from 'utils/customValidators';
import { ClientDTO, CreateClientDTO } from 'utils/restApplicationClient';
import { addClientContainerStyles } from './addClientContainer.style';

interface IStateProps {
  errors: { [key: string]: string | undefined };
  client: ClientDTO | undefined;
  xtmLanguages: Array<string>;
  contentfulLanguages: Array<string>;
}

interface IDispatchProps {
  createClient: (payload: CreateClientDTO) => AppDispatch;
  editClient: (payload: IEditClient) => AppDispatch;
  getClientById: (payload: string) => AppDispatch;
  clearClient: () => AppDispatch;
  clearClientErrors: () => AppDispatch;
  getAllLanguages: () => AppDispatch;
}

export interface IClientForm
  extends Omit<CreateClientDTO, 'contentfulXtmLanguagesMap'> {
  contentfulXtmLanguagesMap: Array<ILanguageMappingField>;
}

// eslint-disable-next-line max-lines-per-function
export const AddClientForm = (
  props: IStateProps & IDispatchProps,
): JSX.Element => {
  const {
    params: { id: clientId },
  } = useRouteMatch<{ id: string }>();

  const { client } = props;
  const { t } = useTranslation();
  const {
    errors,
    createClient,
    editClient,
    xtmLanguages,
    contentfulLanguages,
  } = props;
  const [mappingInitialValue, setMappingInitialValue] = useState<
    Array<ILanguageMappingField> | undefined
  >(undefined);
  const [submitValues, setSubmitValues] = useState<{
    clientName?: string;
    status?: boolean;
  }>({
    clientName: undefined,
    status: undefined,
  });

  useEffect(() => {
    const { getAllLanguages: getXTMLanguages } = props;

    getXTMLanguages();

    if (clientId) {
      props.getClientById(clientId);
    }
    return (): void => {
      props.clearClient();
      props.clearClientErrors();
    };
  }, []);

  useEffect(() => {
    setMappingInitialValue(
      !client
        ? undefined
        : Object.entries(client.contentfulXtmLanguagesMap).map(
            ([contentfulLanguageCode, xtmLangaugeCode]) => ({
              contentfulLanguage: {
                label: contentfulLanguageCode,
                value: contentfulLanguageCode,
              },
              xtmLanguage: { label: xtmLangaugeCode, value: xtmLangaugeCode },
            }),
          ),
    );
  }, [client]);

  const mapLanguages = (
    languageArray: Array<string>,
  ): Array<IAutocompleteField<string>> =>
    languageArray.map((code) => ({ value: code, label: code }));

  const mapLanguagesToObject = (
    contentfulXtmLanguagesMap: Array<ILanguageMappingField>,
  ): { [index: string]: string } => {
    const mappedLanguages: { [index: string]: string } = {};

    // eslint-disable-next-line no-unused-expressions
    contentfulXtmLanguagesMap?.forEach(
      ({ contentfulLanguage, xtmLanguage }) => {
        mappedLanguages[contentfulLanguage.value] = xtmLanguage.value;
      },
    );

    return mappedLanguages;
  };

  const onSubmit = (values: IClientForm): void => {
    const contentfulXtmLanguagesMap = mapLanguagesToObject(
      values.contentfulXtmLanguagesMap,
    );
    props.clearClientErrors();
    setSubmitValues(values);
    if (clientId) {
      editClient({
        clientId,
        updateClient: { ...values, contentfulXtmLanguagesMap },
      });
    } else {
      createClient({ ...values, contentfulXtmLanguagesMap });
    }
  };

  return (
    <FormDiv>
      <FormTitle text={clientId ? 'client.edit' : 'client.add'} />
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, submitting, pristine, form }): JSX.Element => (
          <form onSubmit={handleSubmit} css={addClientContainerStyles.form}>
            <Field
              name="clientName"
              initialValue={client && client.clientName}
              validate={composeValidators([
                required,
                fetchValidator(errors['clientName'], submitValues.clientName),
              ])}
              key={
                errors['clientName']
                  ? `clientNameError${submitValues.clientName}`
                  : 'clientName'
              }
            >
              {({ input, meta }): JSX.Element => (
                <CustomFieldRow
                  label={t('client.clientName')}
                  error={meta.error}
                  touched={errors['clientName'] ? true : meta.touched}
                  inputProps={input}
                  testId="clientNameInput"
                />
              )}
            </Field>
            <Field
              name="status"
              type="checkbox"
              initialValue={clientId ? client && client.status : true}
            >
              {({ input }): JSX.Element => (
                <CustomCheckbox
                  label="common.active"
                  inputProps={input}
                  testId="checkboxInput"
                />
              )}
            </Field>
            <Field
              name="enabledEntryLevelLocalization"
              type="checkbox"
              initialValue={
                clientId
                  ? client && !!client.enabledEntryLevelLocalization
                  : false
              }
            >
              {({ input }): JSX.Element => (
                <CustomCheckbox
                  label="client.enableEntryLevelLocalization"
                  inputProps={input}
                />
              )}
            </Field>
            <Field
              name="enabledTags"
              type="checkbox"
              initialValue={clientId ? client && !!client.enabledTags : true}
            >
              {({ input }): JSX.Element => (
                <CustomCheckbox
                  label="client.applyTags"
                  inputProps={input}
                  testId="checkboxInput"
                />
              )}
            </Field>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (window as any)._env_?.REACT_APP_IS_NON_HUMAN_TAGGING_ENABLED ===
                'true' && (
                <Field
                  name="nonHumanTranslationCustomFieldName"
                  initialValue={
                    client && client.nonHumanTranslationCustomFieldName
                  }
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomFieldRow
                      label={t('client.nonHumanTranslationCustomFieldName')}
                      error={meta.error}
                      touched={meta.touched}
                      inputProps={input}
                    />
                  )}
                </Field>
              )
            }

            <LanguageMappingContainer
              form={form}
              contentfulLanguages={mapLanguages(contentfulLanguages)}
              xtmLanguages={mapLanguages(xtmLanguages)}
              initialValue={mappingInitialValue}
            />
            {client && (
              <EditData
                createdAt={client.createdAt}
                modifiedAt={client.modifiedAt}
              />
            )}

            <ButtonContainer
              backTo="/clients"
              submitting={submitting || pristine}
            />
          </form>
        )}
      />
    </FormDiv>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  createClient: (payload: CreateClientDTO): AppDispatch =>
    dispatch(createClient(payload)),
  editClient: (payload: IEditClient): AppDispatch =>
    dispatch(editClient(payload)),
  getClientById: (payload: string): AppDispatch =>
    dispatch(getClientById(payload)),
  clearClient: (): AppDispatch => dispatch(clearClient()),
  clearClientErrors: (): AppDispatch => dispatch(clearClientErrors()),
  getAllLanguages: (): AppDispatch => dispatch(getAllLanguages()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getClientErrorsSelector(state),
  client: getClientSelector(state),
  contentfulLanguages: getContentfulLanguagesSelector(state),
  xtmLanguages: getXTMLanguagesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm);
