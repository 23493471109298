import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  Select,
  TextField,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface FieldDivProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  column?: boolean;
}

interface CustomAutocompleteTypographyProps extends TypographyProps {
  column?: boolean | string;
  component?: string;
}

interface StyledAutocompleteTextFieldProps {
  draggable?: boolean;
}

export const customInputStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    margin: 4px auto;
    width: 100%;
  `,
  label: css`
    font-weight: bold;
    color: #333;
    text-align: start;
    max-width: 300px;
  `,
  outlineError: css`
    &:hover {
      fieldset {
        border-color: red !important;
        border-width: 3px;
      }
    }

    fieldset {
      border-color: red !important;
      border-width: 2px;
    }
  `,
  textError: css`
    color: red;
    text-align: start;
    max-width: 400px;
    margin-left: 300px;
  `,
  textErrorNoMargin: css`
    color: red;
    text-align: start;
    max-width: 400px;
  `,
  checkbox: css`
    display: flex;
  `,
};

export const ErrorDiv = styled.div`
  width: 100%;
  text-align: left;
`;

export const FieldDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 409px;
  & svg {
    font-size: 28px;
  }
`;

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
`;

export const StyledSelect = styled(Select)`
  width: 400px !important;
`;

export const InputDiv = styled.div(
  ({ column }: FieldDivProps) => `
  display: flex;
  flex-direction: ${column ? 'column' : 'row'};
  justify-content: space-between;
  align-items: ${column ? 'stretch' : 'baseline'};
  margin: 4px auto;
  width: 100%;
  text-align: start;

  .MuiAutocomplete-inputRoot {
    padding-top: 3.8px !important;
    padding-bottom: 3.8px !important;
  }
`,
);

export const CustomEndAdornment = styled.div`
  position: absolute;
  right: 12px;
`;

export const CustomDraggableContainer = styled.div`
  display: flex;
  overflow: auto;
  margin-top: 5px;
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    background-color: #dde0e6;
  }
`;

export const CustomAutocompleteTypography = styled(Typography)(
  ({ column }: CustomAutocompleteTypographyProps) => `
  align-self: ${column ? 'start' : 'center'};
`,
);

export const AutocompleteTextField = styled(TextField)(
  ({ draggable }: StyledAutocompleteTextFieldProps) => `
  .MuiAutocomplete-popupIndicator {
    margin-right: -4px;
  }

 ${
   draggable
     ? `
    .MuiInputBase-root {
      display:flex;
      align-items: flex-start;
      flex-direction: column-reverse;
      padding: 6px !important;
      cursor: auto;

      .MuiInputBase-input {
      width: calc(100% - 58px);
      }

      .MuiAutocomplete-endAdornment {
        top:5px;
      }
    }`
     : ''
 };
`,
);

export const AutocompleteChipDefaultSpan = styled.span`
  color: rgba(0, 0, 0, 0.35);
  padding-left: 5px;
`;
export const CustomTypography = styled(Typography)`
  margin: 6px 0 !important;
`;
